<template>
  <b-card
    class="appversion-edit-wrapper"
  >
    <!-- form -->
    <b-form id="appversionForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group label-cols="3" label-cols-lg="3" label="应用名称" label-for="type" label-size="sm" class="mb-1">
            <b-form-input id="type" size="sm" v-model="appversion.type"/>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label-cols="3" label-cols-lg="3" label="设备平台" label-for="platform" label-size="sm" class="mb-1">
            <v-select id="appversion_platform" :options="options" :clearable="false" v-model="appversion.platform"
                     class="select-size-sm" placeholder="选择设备平台"/>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label-cols="3" label-cols-lg="3" label="版本号" label-for="version" label-size="sm" class="mb-1">
            <b-form-input id="version" size="sm" v-model="appversion.version"/>
          </b-form-group>
        </b-col>
        <b-col md="3">

          <b-form-group label-cols="3" label-cols-lg="3" label="上传安装包：" label-for="url" label-size="sm" class="mb-1">
            <attachment-upload v-if="appversion.loaded" :theme="'file'"
                               :attachment_id="'attachments'"
                               :id="appversion.attachments"
                               :object_type="'appversion'"
                               :object_id="appversion.path"
                               @change="onUploaded"
            />
<!--            <b-form-input id="url" size="sm" v-model="appversion.url"/>-->
          </b-form-group>
        </b-col>
<!--        <b-col md="3">
          <b-form-group label-cols="3" label-cols-lg="3" label="状态" label-for="state" label-size="sm" class="mb-1">
            <b-form-input id="state" size="sm" v-model="appversion.state"/>
          </b-form-group>
        </b-col>-->
<!--        <b-col md="3">
          <b-form-group label-cols="3" label-cols-lg="3" label="创建时间" label-for="create_time" label-size="sm" class="mb-1">
            <b-form-input id="create_time" size="sm" v-model="appversion.create_time"/>
          </b-form-group>
        </b-col>-->
<!--        <b-col md="3">
          <b-form-group label-cols="3" label-cols-lg="3" label="创建人id" label-for="creator" label-size="sm" class="mb-1">
            <b-form-input id="creator" size="sm" v-model="appversion.creator"/>
          </b-form-group>
        </b-col>-->
        <b-col md="3">
          <b-form-group label-cols="3" label-cols-lg="3" label="备注" label-for="memo" label-size="sm" class="mb-1">
            <b-form-input id="memo" size="sm" v-model="appversion.memo"/>
          </b-form-group>
        </b-col>
        <b-col md="3">
<!--          <b-form-group label-cols="3" label-cols-lg="3" label="更新日志" label-for="update_item" label-size="sm" class="mb-1">-->
<!--            <b-form-input id="update_item" size="sm" v-model="appversion.update_item"/>-->
<!--          </b-form-group>-->
        </b-col>
        <b-col cols="12" class="mt-50">
          <b-button variant="primary" class="mr-1" @click="save">保存</b-button>
          <b-button variant="outline-secondary" @click="cancel">取消</b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import appversionStore from './appversionStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      appversion: ref({}),
      options: ['android','ios'],
    }
  },
  setup() {
    const onUploaded = function (id, attachment, result) {
      this.appversion[id] = result
      this.appversion.url=attachment.path
    }
    const toast = useToast()

    // Register module
    if (!store.hasModule('appversion')) store.registerModule('appversion', appversionStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('appversion')) store.unregisterModule('appversion')
    })

    const edit = function() {
      store.dispatch('appversion/edit', {id: this.id}).then(res => {
        this.appversion = res.data.data
      })
    }

    const view = function() {
      store.dispatch('appversion/view', {id: this.id}).then(res => {
        this.appversion = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
        store.dispatch('appversion/save', this.appversion).then(res => {
          toast.success('数据已保存!')
          this.$router.push({ name: 'apps-${classVar}-list'});
        })

    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      onUploaded,
    }
  },
  created() {

    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
